import { Outlet } from "react-router-dom";

const Notice = () => {
	return (
		<div className="bg-bg01 rounded-[36px] flex-1 py-[24px]">
			<div className="px-[44px]">
				<h3 className="text-textWhite text-[20px] font-medium pb-[12px]">공지사항</h3>
			</div>
			<Outlet />
		</div>
	);
};

export default Notice;
