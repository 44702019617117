import styles from "./button-group.module.scss";
import { Button, ButtonProps, HelpButton } from "../../common";
import { ShareButton } from "../share-button";
import { environment } from "../../../../environments/environment";

const Buttons: Array<ButtonProps> = [
	{
		title: "App Store",
		color: "white",
		imgSrc: "assets/icons/ic_appstore.svg",
		link: environment.WAYBIT_APP_STORE_URL,
	},
	{
		title: "Google Play",
		color: "white",
		imgSrc: "assets/icons/ic_googleplay.svg",
		link: environment.WAYBIT_PLAY_STORE_URL,
	},
];

const ButtonGroup = () => {
	return (
		<div className={styles["button-group"]}>
			{Buttons.map((button) => (
				<Button key={button.title} {...button} />
			))}
			<ShareButton />
			<div className="lg:hidden">
				<HelpButton />
			</div>
		</div>
	);
};

export default ButtonGroup;
