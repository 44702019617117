import { useState, useEffect } from "react";
// import { commonPackage } from "@waybit/shared";
import axios from "axios";
import { environment } from "../../environments/environment";

const useBoardContents = (option: { boardContentsId: string }) => {
	const [boardContents, setBoardContents] = useState<any>();

	useEffect(() => {
		const getBoardContents = async () => {
			try {
				// const devUrl = `${environment.SERVICE_COMMON_DEV_SERVER_IP}:${environment.SERVICE_COMMON_DEV_SERVER_PORT}/common/getBoardContents`;
				// const prodUrl = `${environment.SERVICE_COMMON_PROD_SERVER_IP}/common/getBoardContents`;

				const apiEndpoint = `${environment.API_URL}/announcements/${option.boardContentsId}`;

				// const metadata = {
				// 	authorization: environment.AUTHORIZATION,
				// 	waybitkey: environment.WAYBIT_KEY,
				// 	waybitsign: environment.WAYBIT_SIGN,
				// 	waybitts: Date.now(),
				// };
				const { data } = await axios.get(apiEndpoint);

				setBoardContents(data);
			} catch (error) {
				console.log(error);
			}
		};

		getBoardContents();
	}, []);

	return boardContents;
};

export default useBoardContents;
