import { Link } from "react-router-dom";

export type LinkItemProps = {
	title: string;
	link: string;
	imgSrc?: string;
};

const LinkItem = (props: LinkItemProps) => {
	const { title, link, imgSrc } = props;

	return (
		<li key={title}>
			<Link to={link}>{imgSrc ? <img src={imgSrc} alt={title} /> : title}</Link>
		</li>
	);
};

export default LinkItem;
