// import { commonPackage } from "@waybit/shared";
import { formatDate, getContentTypeTag } from "../../../../utils";
import { Link } from "react-router-dom";

export type ListItemProps = Omit<any, "isPublic" | "isPinned">;

const BoardItem = (props: ListItemProps) => {
	const { uuid, announcement_type, korean_title, announced_at } = props;

	// const boardContents = useBoardContents({ boardContentsId: id });
	const { name: tagName, color } = getContentTypeTag(announcement_type);

	return (
		<Link to={`board/${uuid!}`} className="flex flex-col items-start tracking-normal h-auto py-[16px] border-b-[1px] border-b-divider first:pt-0">
			<div className="text-textLightgray font-medium">
				<strong className={`${color ?? "text-textWhite"} font-semibold mr-[5px]`}>[{tagName}]</strong>
				{korean_title}
			</div>
			<span className="text-textGray text-[14px]">
				{formatDate(announced_at)}
			</span>
		</Link>
	);
};

export default BoardItem;
