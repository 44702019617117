import { Bar, LibrarySymbolInfo, PeriodParams } from "../../../../assets/charting_library/datafeed-api";
import { makeApiRequest, parseFullSymbol } from "./helper";

// 임시 type
export type BarAllData = Omit<Bar, "volume"> & {
	volumefrom: number;
	volumeto: number;
};

export type PeriodParamsWithOptionalCountback = Omit<PeriodParams, "countBack"> & {
	countBack?: number;
};

export default {
	getBars: async (symbolInfo: LibrarySymbolInfo, resolution: string, periodParams: PeriodParamsWithOptionalCountback, limit?: number) => {
		const { from, to } = periodParams;
		const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
		const urlPath = resolution.includes("D") ? "/data/histoday" : Number(resolution) >= 60 ? "/data/histohour" : "/data/histominute";
		const params = {
			e: parsedSymbol?.exchange,
			fsym: parsedSymbol?.fromSymbol,
			tsym: parsedSymbol?.toSymbol,
			toTs: to ? to : "",
			limit: limit ? limit : 2000,
		};

		try {
			const data = await makeApiRequest(urlPath, params);
			if ((data.Response && data.Response === "Error") || data.Data.length === 0) {
				// "noData" should be set if there is no data in the requested period
				return {
					bars: [],
					meta: { noData: true },
				};
			}
			let bars: Bar[] = [];
			data.Data.forEach((bar: BarAllData) => {
				if (bar.time >= from && bar.time < to) {
					bars = [
						...bars,
						{
							time: bar.time * 1000,
							low: bar.low,
							high: bar.high,
							open: bar.open,
							close: bar.close,
							volume: bar.volumefrom,
						},
					];
				}
			});

			return {
				bars,
				meta: { noData: false },
			};
		} catch (error) {
			console.log("[getBars]: Get error", error);

			return {
				bars: [],
				meta: { noData: true },
			};
		}
	},
};
