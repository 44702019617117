import { LinkListProps, LinkList } from "./link-list";
import { Copyright } from "./copyright";
import { Address } from "./address";

const InfoList: LinkListProps = {
	items: [
		{ title: "FAQ", link: "https://www.notion.so/guardianholdings/FAQ-a1bffe4d704f425596ccec4b8b0fe59a" },
		{ title: "이용약관", link: "https://docs.google.com/document/d/1M_ffDMpueLc0Y0cHFffr2aLXQm3Xeuip83o1me65X2E/edit?usp=sharing" },
		{ title: "개인정보 처리방침", link: "https://docs.google.com/document/d/1PQU3a628B24U_wsRHqyH91W6cBgk_xuGZWP4eP2pwJ0/edit?usp=sharing" },
	],
	className: "info-list",
};

const SnsList: LinkListProps = {
	items: [
		{
			title: "youtube",
			link: "#",
			imgSrc: "assets/icons/ic_youtube.svg",
		},
		{
			title: "twitter",
			link: "#",
			imgSrc: "assets/icons/ic_twitter.svg",
		},
	],
	className: "sns-list",
};

const Footer = () => {
	return (
		<footer className="lg:flex justify-center bg-bg01 hidden">
			<div className="flex flex-col gap-y-[22px] w-[1200px] py-[60px] text-textGray px-[16px] xl:px-0">
				<div className="flex justify-between">
					<LinkList {...InfoList} />
					{/*<LinkList {...SnsList} />*/}
				</div>
				<div className="flex justify-between items-end">
					<Address />
					<Copyright />
				</div>
			</div>
		</footer>
	);
};

export default Footer;
