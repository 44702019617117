import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./app/app";
import { BoardDetail, BoardList,
	TVChartContainer
} from "./app/components";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />}>
					<Route index element={<BoardList />} />
					<Route path="board/:id" element={<BoardDetail />} />
				</Route>
				<Route path="/chart/:id" element={<TVChartContainer />} />
			</Routes>
		</BrowserRouter>
	</StrictMode>
);
