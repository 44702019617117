type ToastProps = {
	message: string;
	className: string;
	show: boolean;
};

const Toast = (props: ToastProps) => {
	const { message, className, show } = props;

	return (
		<>
			{show && (
				<div className={`absolute opacity-90 ${className}`}>
					<div className="bg-bg03 w-[280px] py-[20px] px-[16px] text-center rounded-[300px]">
						<strong className="text-textWhite text-[14px]">{message}</strong>
					</div>
				</div>
			)}
		</>
	);
};

export default Toast;
