import { Button, Toast } from "../../common";
import { useCallback, useState } from "react";

const ShareButton = () => {
	const [isShared, setIsShared] = useState<boolean>(false);

	const autoToastRemove = useCallback(() => {
		const timeoutId = setTimeout(() => {
			setIsShared(false);
		}, 5000);

		return () => {
			clearTimeout(timeoutId);
		};
	}, []);

	const handleShareClick = useCallback(async () => {
		const url = "https://oasisexc.com";

		try {
			await navigator.clipboard.writeText(url);
			setIsShared(true);
			autoToastRemove();
		} catch (error) {
			console.error(error);
			setIsShared(false);
		}
	}, [autoToastRemove]);

	return (
		<div className="relative hidden lg:block">
			<Button title="공유하기" color="gray" onClick={handleShareClick} />
			<Toast message="주소가 복사되었습니다." className="top-[117px] right-[364px]" show={isShared} />
		</div>
	);
};

export default ShareButton;
