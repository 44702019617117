const Address = () => {
	return (
		<address className="text-[13px] not-italic">
			<strong className="mb-[10px] block text-[15px]">(주)가디언홀딩스</strong>
			사업자 등록번호 : 826-81-00097
			<span className="px-[8px]">|</span>
			대표 : 이동민
			<span className="px-[8px]">|</span>
			고객센터 : 1670-1899
			<span className="block mb-[10px]" />
			06187 서울특별시 강남구 역삼로 546, 2층 (대치동, 덕일빌딩)
		</address>
	);
};

export default Address;
