import styles from "./button.module.scss";

export type ButtonProps = {
	title?: string;
	color?: "white" | "gray";
	imgSrc?: string;
	link?: string;
	className?: string;
	onClick?: () => void;
};

const HelpButtonProps: ButtonProps = {
	title: "고객센터",
	color: "gray",
	imgSrc: "../../../assets/icons/ic_kakaotalk.svg",
	link: "https://pf.kakao.com/_QVxexjT",
	className: "!text-textYellow",
};

const HelpButton = (props: ButtonProps) => <Button {...HelpButtonProps} />;

const Button = (props: ButtonProps) => {
	const { title, color, imgSrc, link, className, onClick } = props;

	return (
		<a key={title} href={link && link} className={`${color && styles[`${color}-button`]} ${className}`} onClick={onClick}>
			{imgSrc && <img src={imgSrc} alt={`${title} icon`} />}
			{title}
		</a>
	);
};

export { Button, HelpButton };
