import { Guide } from "./guide";
import { Banner } from "./banner";
import { Notice } from "./notice";
import { AppView } from "./app-view";
import { ButtonGroup } from "./button-group";

const Contents = () => {
	return (
		<div className="lg:pt-[80px] lg:pb-[130px] flex justify-center gap-x-[24px] h-screen lg:h-auto">
			<div className="lg:flex flex-col gap-y-[24px] w-[560px] xl:w-[648px] hidden">
				<Guide />
				<Banner />
				<Notice />
			</div>
			<div className="flex flex-col justify-center items-center gap-y-[32px] p-[24px] lg:p-0 w-screen lg:w-[483px] bg-bg01 rounded-[36px]">
				<AppView />
				<div className="hidden lg:block">
					<ButtonGroup />
				</div>
			</div>
		</div>
	);
};

export default Contents;
