const formatDate = (announcedAt: string | undefined) => {
  if (!announcedAt) {
    return;
  }

  const date = new Date(announcedAt);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}. ${month}. ${day}`;
};

export default formatDate;
