import { getContentTypeTag } from './index';

const filterList = (list: any[] = [], search: string = '') => {
  if (search === '') {
    return list;
  }

  return list.filter((data) => {
    const tag = getContentTypeTag(data.announcement_type);
    return `[${tag}] ${data.korean_title}`.toLowerCase().includes(search);
  });
};

export default filterList;
