import historyProvider from "./history-provider";
import { DatafeedConfiguration, ErrorCallback, HistoryCallback, LibrarySymbolInfo, OnReadyCallback, ResolutionString, SubscribeBarsCallback } from "../../../../assets/charting_library/datafeed-api";
import { PeriodParamsWithOptionalCountback } from "./history-provider";
import { subscribeOnStream, unsubscribeFromStream } from "./stream";
import { parseFullSymbol } from "./helper";

export type ResolveCallback = (symbolInfo: Partial<LibrarySymbolInfo>) => void;

const configurationData: DatafeedConfiguration = {
	supported_resolutions: ["1", "3", "5", "10", "15", "30", "60", "240", "D", "7D", "M"] as ResolutionString[],
};

const lastBarsCache = new Map();

export default {
	onReady: (cb: OnReadyCallback): void => {
		setTimeout(() => cb(configurationData), 0);
	},
	resolveSymbol: (symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback): void => {
		// expects a symbolInfo object in response
		const parsedSymbol = parseFullSymbol(symbolName);
		const symbolInfo: Partial<LibrarySymbolInfo> = {
			ticker: "Bitfinex:BTC/USD",
			name: "BTC/USD",
			description: "BTC/USD",
			type: "crypto",
			session: "24x7",
			timezone: "Etc/UTC",
			exchange: "Bitfinex",
			minmov: 1,
			pricescale: 100,
			has_intraday: true,
			has_no_volume: false,
			has_weekly_and_monthly: false,
			supported_resolutions: configurationData.supported_resolutions as LibrarySymbolInfo["supported_resolutions"],
			volume_precision: 2,
			data_status: "streaming",
		};

		if (parsedSymbol?.toSymbol.match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
			symbolInfo.pricescale = 100;
		}

		onResolve(symbolInfo);
	},
	getBars: async (symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParamsWithOptionalCountback, onResult: HistoryCallback, onError: ErrorCallback): Promise<void> => {
		const { firstDataRequest } = periodParams;
		try {
			const { bars, meta } = await historyProvider.getBars(symbolInfo, resolution, periodParams);
			if (firstDataRequest) {
				lastBarsCache.set(symbolInfo.full_name, {
					...bars[bars.length - 1],
				});
			}
			onResult(bars, meta);
		} catch (error: any) {
			console.log(error);
			onError(error);
		}
	},
	subscribeBars: (symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onRealtimeCallback: SubscribeBarsCallback, subscriberUID: string, onResetCacheNeededCallback: () => void) => {
		subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback, lastBarsCache.get(symbolInfo.full_name));
	},
	unsubscribeBars: (subscriberUID: string) => {
		unsubscribeFromStream(subscriberUID);
	},
};
