const Copyright = () => {
	return (
		<div className="flex flex-col gap-y-[8px] items-end">
			<div className="w-[88px]">
				<img src="../../../assets/images/footer_logo.png" alt="logo" />
			</div>
			<small className="block text-[13px]">© Guardian Holdings. All rights reserved.</small>
		</div>
	);
};

export default Copyright;
