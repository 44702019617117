import { Contents, Footer, Navigation } from "./components";

const App = () => {
	return (
		<div className="bg-bg02 flex flex-col min-h-screen justify-center xl:justify-start">
			<Navigation />
			<Contents />
			<Footer />
		</div>
	);
};

export default App;
