import { HelpButton } from "../common";
import Logo from "../../../assets/images/logo.png";

const Navigation = () => {
	return (
		<div className="lg:flex justify-center h-[80px] bg-bg01 hidden">
			<div className="flex justify-between items-center w-[1155px] px-[16px] xl:px-0">
				<div className="w-[124px]">
					<img src={Logo} alt="logo" />
				</div>
				<HelpButton />
			</div>
		</div>
	);
};

export default Navigation;
