import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { widget, ChartingLibraryWidgetOptions, LanguageCode, ResolutionString } from "../../../assets/charting_library";
import Datafeed from "./api/datafeed";

export interface ChartContainerProps {
	symbol: ChartingLibraryWidgetOptions["symbol"];
	interval: ChartingLibraryWidgetOptions["interval"];

	// BEWARE: no trailing slash is expected in feed URL
	datafeedUrl: string;
	libraryPath: ChartingLibraryWidgetOptions["library_path"];
	chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
	chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
	clientId: ChartingLibraryWidgetOptions["client_id"];
	userId: ChartingLibraryWidgetOptions["user_id"];
	fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
	autosize: ChartingLibraryWidgetOptions["autosize"];
	studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
	overrides: ChartingLibraryWidgetOptions["overrides"];
	container: ChartingLibraryWidgetOptions["container"];
}

const getLanguageFromURL = (): LanguageCode | null => {
	const regex = new RegExp("[\\?&]lang=([^&#]*)");
	// eslint-disable-next-line no-restricted-globals
	const results = regex.exec(location.search);
	return results === null ? null : (decodeURIComponent(results[1].replace(/\+/g, " ")) as LanguageCode);
};

export const TVChartContainer = () => {
	const { symbol } = useParams();
	const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

	useEffect(() => {
		const defaultProps: Omit<ChartContainerProps, "container"> = {
			symbol: "Bitfinex:BTC/USD",
			interval: "1" as ResolutionString,
			datafeedUrl: "https://demo_feed.tradingview.com",
			libraryPath: "../../../assets/charting_library/",
			chartsStorageUrl: "https://saveload.tradingview.com",
			chartsStorageApiVersion: "1.1",
			clientId: "tradingview.com",
			userId: "public_user_id",
			fullscreen: false,
			autosize: true,
			studiesOverrides: {
				"volume.volume.color.0": "#FF3434",
				"volume.volume.color.1": "#1E6DFF",
				"volume.volume.transparency": 0,
			},
			overrides: {
				volumePaneSize: "small",
			},
		};

		const widgetOptions: ChartingLibraryWidgetOptions = {
			debug: false,
			symbol: defaultProps.symbol as string,
			datafeed: Datafeed as unknown as ChartingLibraryWidgetOptions["datafeed"],
			interval: defaultProps.interval as ChartingLibraryWidgetOptions["interval"],
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath as string,

			locale: getLanguageFromURL() || "ko",
			disabled_features: ["header_symbol_search", "header_compare", "header_settings", "header_saveload", "header_undo_redo", "left_toolbar", "timeframes_toolbar", "volume_force_overlay", "legend_widget"],
			charts_storage_url: defaultProps.chartsStorageUrl,
			charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
			overrides: defaultProps.overrides,
		};

		const tvWidget = new widget(widgetOptions);

		tvWidget.onChartReady(() => {
			tvWidget.changeTheme("Dark").then(() => {
				tvWidget.applyOverrides({
					"mainSeriesProperties.candleStyle.upColor": "#FF3434",
					"mainSeriesProperties.candleStyle.downColor": "#1E6DFF",
					"mainSeriesProperties.candleStyle.borderUpColor": "#FF3434",
					"mainSeriesProperties.candleStyle.borderDownColor": "#1E6DFF",
					"mainSeriesProperties.candleStyle.wickUpColor": "#FF3434",
					"mainSeriesProperties.candleStyle.wickDownColor": "#1E6DFF",
				});
			});
		});

		return () => {
			tvWidget.remove();
		};
	}, []);

	return <div ref={chartContainerRef} className={"TVChartContainer h-screen"} />;
};
