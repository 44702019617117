import { Pagination, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const ImageSrc: Array<string> = ["../../../assets/images/banner02.png"];

const Banner = () => {
	return (
		<Swiper className="w-full" modules={[Pagination, Autoplay]} pagination={{ clickable: true }} autoplay={{ delay: 3000 }}>
			{ImageSrc.map((src, i) => (
				<SwiperSlide key={i}>
					<img src={src} alt={`banner${i}`} />
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default Banner;
