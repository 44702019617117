import { useMemo, useState } from "react";
import { useListBoard } from "../../../../hooks";
// import { commonPackage } from "@waybit/shared";
import BoardItem from "../board-item/board-item";
import SearchBar from "../search-bar/search-bar";
import { filterList } from "../../../../utils";

const BoardOptions: { announcement_type: string } = {
	announcement_type: "NOTICES",
};

const BoardList = () => {
	const list = useListBoard(BoardOptions);

	const [search, setSearch] = useState("");
	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		setSearch(e.target.value.toLowerCase());
	};

	const visibleList = useMemo(() => filterList(list, search), [list, search]);

	return (
		<>
			<SearchBar value={search} onChange={handleSearchChange} />
			<div className="mx-[8px] pt-[24px] px-[36px] h-[497px] overflow-y-auto">
				{visibleList?.map((item) => (
					<BoardItem key={item.id} {...item} />
				))}
			</div>
		</>
	);
};

export default BoardList;
