import { useState, useEffect } from "react";
// import { commonPackage } from "@waybit/shared";
import axios from "axios";
import { environment } from "../../environments/environment";

const useListBoard = (option: { announcement_type: string }) => {
	const [listBoard, setListBoard] = useState<any>();

	useEffect(() => {
		const getListBoard = async () => {
			try {
				// const devUrl = `${environment.SERVICE_COMMON_DEV_SERVER_IP}:${environment.SERVICE_COMMON_DEV_SERVER_PORT}/common/listBoard`;
				// const prodUrl = `${environment.SERVICE_COMMON_PROD_SERVER_IP}/common/listBoard`;

				const apiEndpoint = `${environment.API_URL}/announcements/`;

				// const metadata = {
				// 	authorization: environment.AUTHORIZATION,
				// 	waybitkey: environment.WAYBIT_KEY,
				// 	waybitsign: environment.WAYBIT_SIGN,
				// 	waybitts: Date.now(),
				// };
				const { data } = await axios.get<any>(apiEndpoint, {
					params: {
						page_size: 1000,
						...option
					}
				});
				const boardData = data.results;
				setListBoard(boardData);
			} catch (error) {
				console.log(error);
			}
		};

		getListBoard();
	}, [option]);

	return listBoard;
};

export default useListBoard;
