import SplashMainLogo from "../../../../assets/images/splash_main_logo.png";
import SplashBottomLogo from "../../../../assets/images/oasis_logo_P.png";
import { ButtonGroup } from "../button-group";

const AppView = () => {
	return (
		<div className="flex flex-col justify-center items-center w-[90vw] max-w-[390px] h-full max-h-[844px] border-2 rounded-[20px] overflow-hidden">
			{/*<iframe title="app" src="https://waybit-98b6d.web.app" width="390" height="844" frameBorder="0"></iframe>*/}
			<div className="flex flex-col flex-1 justify-between items-center pt-[266px] pb-[32px]">
				<img className="w-[168px] h-[146px]" src={SplashMainLogo} alt="splash main image" />
				<img className="w-[148px] h-[18px]" src={SplashBottomLogo} alt="splash bottom image" />
			</div>
			<div className="lg:hidden">
				<ButtonGroup />
			</div>
		</div>
	);
};

export default AppView;
