import { Button, ButtonProps } from "../../common";

const GuideButton: ButtonProps = {
	title: "이용가이드 보기",
	color: "gray",
	link: "https://guardianholdings.notion.site/84412bcba4724078b03c01fb6da414c9",
	className: "w-[157px] !px-0",
};

const Guide = () => {
	return (
		<div className="flex justify-between items-center bg-bg01 p-[44px] rounded-[36px] font-medium">
			<p className="text-textWhite rounded-[36px] text-[32px] leading-tight">
				안녕하세요.
				<br />
				오아시스 거래소입니다.
			</p>
			<Button {...GuideButton} />
		</div>
	);
};

export default Guide;
