// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
	production: false,
	SERVICE_COMMON_SERVER_IP: "http://localhost:58053",
	API_URL: "https://prod-api.oasisexc.com/api/v1",
	AUTHORIZATION: "v4.public.eyJzdWIiOiI3ZTQ4ZjZiOS03ZWNmLTQ3MWQtOGYyMy1hNjE1NjU0ODE4YzJ8MTAwMjkiLCJpYXQiOiIyMDIzLTEwLTIzVDAxOjA4OjI0LjcxN1oiLCJleHAiOiIyMDIzLTExLTIyVDAxOjA4OjI0LjcxN1oifSvneKRP4pNcpg-ELwhgFKOlVojIKwpMeZ9STklHck0KobAnTHX-OYdX6LlNrljBZk0ULAJiQUvxjH--1XQDNQw",
	WAYBIT_KEY: "k4.secret.ySRkdOqRH4MBT_fZJzWJPmz_VubRnhzXjU02fhFjIXvnutjwvYwQV_p5KxX5xwCTrnZ8YRHB8xIUndz5i2G2mA",
	WAYBIT_SIGN: "8ccd8a8ad9b3cfe51895c98839a13388c8778ec5c05b4ed939de17e1ab4b6418fdd93772502764b25953a7664a9d62aa",
	WAYBIT_APP_STORE_URL: "https://apps.apple.com/kr/app/%EC%98%A4%EC%95%84%EC%8B%9C%EC%8A%A4-%EA%B1%B0%EB%9E%98%EC%86%8C-%ED%8A%B8%EB%A0%8C%EB%94%94%ED%95%9C-%EC%BD%94%EC%9D%B8-%EA%B1%B0%EB%9E%98/id6504232616",
	WAYBIT_PLAY_STORE_URL: "https://play.google.com/store/apps/details?id=com.guardianHoldings.oasis&pli=1",
};
