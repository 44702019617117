// import { commonPackage } from "@waybit/shared";

type Tag = {
	name: string;
	color?: string;
};

const tagMap: Record<string, Tag> = {
	TOKEN_SALE: { name: "토큰 판매", color: "text-textBlue" },
	NOTICES: { name: "공지" },
	LISTING: { name: "상장", color: "text-textYellow" },
	FIXED_INCOME: { name: "고정 수익", color: "text-textLightblue" },
	EVENT: { name: "이벤트", color: "text-textBlue" },
	MAINTENANCE: { name: "점검", color: "text-textRed" },
	FAQ: { name: "자주 묻는 질문" },
};

const getContentTypeTag = (type: string | undefined): Tag => {
	return tagMap[type ?? ""] || { name: "" };
};

export default getContentTypeTag;
