import { useNavigate, useParams } from "react-router-dom";
import { Button as BackButton } from "../../../common";
import { formatDate } from "../../../../utils";
import { useBoardContents } from "../../../../hooks";

import "./board-detail.module.scss";

const BoardDetail = () => {
	const navigate = useNavigate();

	const { id } = useParams();
	const boardContents = useBoardContents({ boardContentsId: id! });
	const subjectEllipsis = `${boardContents?.korean_title?.slice(0, 19)}...`;

	return (
		<>
			<div className="flex justify-center items-center h-[40px] mx-[44px]">
				<BackButton imgSrc="../../../assets/icons/ic_back.svg" className="float-left h-auto" onClick={() => navigate("/")} />
				<div className="flex-1 text-textLightgray text-center text-[18px] font-semibold">{subjectEllipsis}</div>
			</div>
			<div className="mx-[8px] pt-[24px] px-[36px] h-[497px] overflow-y-auto">
				<div className="flex flex-col pb-[24px]">
					<strong className="text-textWhite text-[20px] font-semibold leading-[32px] break-words">{boardContents?.korean_title}</strong>
					<span className="text-textGray text-[15px] font-medium">{formatDate(boardContents?.created || boardContents?.announced_at)}</span>
				</div>
				<p dangerouslySetInnerHTML={{ __html: boardContents?.korean_content }} className='text-textLightgray whitespace-pre-wrap break-words'></p>
			</div>
		</>
	);
};

export default BoardDetail;
