// Makes requests to CryptoCompare API
export interface RequestParams {
	[paramName: string]: string | string[] | number | any;
}

export async function makeApiRequest(urlPath: string, params?: RequestParams) {
	try {
		if (params !== undefined) {
			const paramKeys = Object.keys(params);
			if (paramKeys.length !== 0) {
				urlPath += "?";
			}

			urlPath += paramKeys
				.map((key: string) => {
					return `${encodeURIComponent(key)}=${encodeURIComponent(params[key].toString())}`;
				})
				.join("&");
		}

		const response = await fetch(`https://min-api.cryptocompare.com${urlPath}`);
		if (response.ok) {
			return response.json();
		}
	} catch (error: any) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange: string, fromSymbol: string, toSymbol: string) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol: string) {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}
	return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] };
}

const isLoggingEnabled = false;
export function logMessage(message: string): void {
	if (isLoggingEnabled) {
		const now = new Date();
		// tslint:disable-next-line:no-console
		console.log(`${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`);
	}
}

export function getErrorMessage(error: string | Error | undefined): string {
	if (error === undefined) {
		return "";
	} else if (typeof error === "string") {
		return error;
	}

	return error.message;
}
