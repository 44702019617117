import styles from "./link-list.module.scss";
import { LinkItemProps, ListItem } from "../link-item";
import Pdf from "../../../../assets/files/asset_listing_policy.pdf";

export type LinkListProps = {
	items: Array<LinkItemProps>;
	className: string;
};

const LinkList = (props: LinkListProps) => {
	const { items, className } = props;

	const handleOpenPdf = () => {
		window.open(Pdf, "_blank");
	};

	return (
		<ul className={`${styles["link-list"]} ${styles[className]}`}>
			<li className="flex items-center">
				<button onClick={handleOpenPdf}>제휴·상장안내</button>
			</li>

			{items.map((item) => (
				<ListItem key={item.title} {...item} />
			))}
		</ul>
	);
};

export default LinkList;
