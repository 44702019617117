// we use Socket.io client to connect to cryptocompare's socket.io stream
import { Bar, LibrarySymbolInfo, ResolutionString, SubscribeBarsCallback } from "../../../../assets/charting_library/datafeed-api";
import { parseFullSymbol } from "./helper";

const socket = new WebSocket("wss://streamer.cryptocompare.com/v2?api_key=634e61acdbdd86c0d37a81298ede9d5e6dc742e8f94061779bae375b05014af1");
// keep track of subscriptions
const channelToSubscription = new Map();

socket.onopen = function onStreamOpen() {
	console.log("[socket] Connected");
};

socket.onclose = function onStreamClose(reason: CloseEvent) {
	console.log(`[socket] Close socket: ${reason.code} - ${reason.reason}`);
};

socket.onerror = function onStreamError(error: Event) {
	console.log("[socket] Error: " + error);
};

socket.onmessage = function onStreamMessage(message: MessageEvent) {
	const data = JSON.parse(message.data);
	const { MARKET, FROMSYMBOL, TOSYMBOL, LASTUPDATE, PRICE, LASTVOLUME } = data;

	const channelString = `5~${MARKET}~${FROMSYMBOL}~${TOSYMBOL}`;
	const subscriptionItem = channelToSubscription.get(channelString);
	if (subscriptionItem === undefined) {
		return;
	}
	const lastDailyBar = subscriptionItem.lastDailyBar;
	const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);

	// TODO: api 에서 값을 정상적으로 불러오지 못해 임시 처리, 후에 수정 필요
	const tradePrice = isNaN(PRICE) ? subscriptionItem.lastDailyBar.close : parseFloat(PRICE);
	const tradeTime = parseInt(LASTUPDATE) * 1000;

	let bar: Bar;
	if (tradeTime >= nextDailyBarTime) {
		bar = {
			time: nextDailyBarTime,
			open: tradePrice,
			high: tradePrice,
			low: tradePrice,
			close: tradePrice,
			volume: LASTVOLUME,
		};
	} else {
		bar = {
			...lastDailyBar,
			high: Math.max(lastDailyBar.high, tradePrice),
			low: Math.min(lastDailyBar.low, tradePrice),
			close: tradePrice,
		};
	}

	subscriptionItem.lastDailyBar = bar;
	console.log(new Date(tradeTime), new Date(nextDailyBarTime));

	// Send data to every subscriber of that symbol
	subscriptionItem.handlers.forEach((handler: any) => handler.callback(bar));
};

function getNextDailyBarTime(barTime: number) {
	const date = new Date(barTime * 1000);
	date.setDate(date.getDate() + 1);

	return date.getTime() / 1000;
}

function isWebSocketOpen(ws: WebSocket) {
	return ws.readyState === ws.OPEN;
}

export function subscribeOnStream(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onRealtimeCallback: SubscribeBarsCallback, subscriberUID: string, onResetCacheNeededCallback: () => void, lastDailyBar: Bar) {
	const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
	// 임시 exchange
	const channelString = `5~CCCAGG~${parsedSymbol?.fromSymbol}~${parsedSymbol?.toSymbol}`;
	const handler = {
		id: subscriberUID,
		callback: onRealtimeCallback,
	};
	let subscriptionItem = channelToSubscription.get(channelString);
	if (subscriptionItem) {
		// Already subscribed to the channel, use the existing subscription
		subscriptionItem.handlers.push(handler);

		return;
	}
	subscriptionItem = {
		subscriberUID,
		resolution,
		lastDailyBar,
		handlers: [handler],
	};
	channelToSubscription.set(channelString, subscriptionItem);
	const subRequest = {
		action: "SubAdd",
		subs: ["5~CCCAGG~BTC~USD"],
	};
	console.log(subscriptionItem);

	if (!isWebSocketOpen(socket)) {
		return;
	}
	socket.send(JSON.stringify(subRequest));
}

export function unsubscribeFromStream(subscriberUID: string) {
	// Find a subscription with id === subscriberUID
	for (const channelString of channelToSubscription.keys()) {
		const subscriptionItem = channelToSubscription.get(channelString);
		const handlerIndex = subscriptionItem.handlers.findIndex((handler: any) => handler.id === subscriberUID);

		if (handlerIndex !== -1) {
			subscriptionItem.handlers.splice(handlerIndex, 1);

			if (subscriptionItem.handlers.length === 0) {
				const subRequest = {
					action: "SubRemove",
					subs: ["5~CCCAGG~BTC~USD"],
				};
				socket.send(JSON.stringify(subRequest));
				channelToSubscription.delete(channelString);
				break;
			}
		}
	}
}
