type SearchBarProps = {
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const SearchBar = (props: SearchBarProps) => {
	const { value, onChange } = props;

	return (
		<div className="flex items-center py-[12px] px-[16px] mx-[44px] bg-bg02 rounded-[6px]">
			<img src="../../../assets/icons/ic_search.svg" alt="search" />
			<input className="bg-bg02 flex-1 text-textLightgray placeholder:text-inputPlaceholder" placeholder="어떤 공지사항을 검색할까요?" value={value} onChange={onChange} />
		</div>
	);
};

export default SearchBar;
